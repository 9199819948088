<!--门禁模块--访客预约记录-->
<template>
  <div id="visitorBookingRecord" v-loading="loading" :element-loading-text="`${$t('visitorBookingRecord.a1')}....`">
    <div class="header">
      <!-- 页面标题 -->
      <my-headertitle>{{ $t("visitorBookingRecord.a2") }}</my-headertitle>
      <!-- 查询栏 -->
      <div class="query">
        <a-row :gutter="[16,16]">
          <!-- 受访人信息 -->
          <a-col :span="8">
            <a-input-group compact>
              <a-select v-model="form.condition1" style="width:50%" :placeholder="$t('visitorBookingRecord.a52')">
                <a-select-option v-for="item in conditions1" :key="item.key" >
                  {{ item.title }}
                </a-select-option>
              </a-select>
              <a-input v-model="form.value1" :placeholder="Interviewee" style="width:50%" allow-clear/>
            </a-input-group>
          </a-col>
          <!-- 拜访事由 -->
          <a-col :span="4">
            <a-select v-model="form.condition6" style="width:100%" :placeholder="$t('visitorBookingRecord.a19')" >
              <a-select-option v-for="item in conditions6" :key="item.key">
                {{ item.title }}
              </a-select-option>
            </a-select>
          </a-col>
          <!-- 工单状态 -->
          <a-col :span="4">
            <a-select v-model="form.condition4" style="width:100%" :placeholder="$t('visitorBookingRecord.a15')" >
              <a-select-option v-for="item in conditions4" :key="item.value">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-col>
          <!-- 到访状态 -->
          <a-col :span="4">
            <a-select v-model="form.condition5" style="width:100%" :placeholder="$t('visitorBookingRecord.a16')" >
              <a-select-option v-for="item in conditions5" :key="item.value">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-col>
          <!-- 备注 -->
          <a-col :span="4">
            <!-- $t('visitorBookingRecord.a16') -->
            <a-input v-model="form.memo" style="width:100%" :placeholder="$t('visitorRecord.a24')" allow-clear >
              
            </a-input>
          </a-col>
          <!-- 访客信息 -->
          <a-col :span="8">
            <a-input-group compact>
              <a-select v-model="form.condition2" style="width:50%" :placeholder="$t('visitorBookingRecord.a51')">
                <a-select-option v-for="item in conditions2" :key="item.key">
                  {{ item.title }}
                </a-select-option>
              </a-select>
              <a-input v-model="form.value2" :placeholder="visitor" style="width:50%" allow-clear/>
            </a-input-group>
          </a-col>
          <!-- 时间 -->
          <a-col :span="14">
            <a-input-group compact>
              <!-- 时间类型选择 -->
              <div style="width:30%">
                <a-select v-model="form.condition3" style="width:100%" :placeholder="$t('visitorBookingRecord.a5')" @change="dateTypeSelect">
                  <a-select-option v-for="item in conditions3" :key="item.key">
                    {{ item.title }}
                  </a-select-option>
                </a-select>
              </div>
              <!-- 初始日期 -->
              <div style="width:30%">
                <a-date-picker v-model="form.date1" show-time :allowClear="false" format="YYYY-MM-DD HH:mm:ss" @change="handleCalendarChange" :disabled-date="disabledStartDate" style="width:100%"/>
              </div>
              <!-- 过渡线 -->
              <div style="width:5%">
                <a-input style=" pointer-events: none; backgroundColor: #fff;text-align: center;padding:0px" placeholder="~"/>
              </div>
              <!-- 终止日期 -->
              <div style="width:30%">
                <a-date-picker  v-model="form.date2" :disabled-date="disabledEndDate" show-time :allowClear="false" format="YYYY-MM-DD HH:mm:ss" style="width:100%"/>
              </div>
            </a-input-group>
          </a-col>
          <!-- 查询按钮 -->
          <a-col :span="2">
            <a-button type="primary" @click="inquire">{{ $t("visitorBookingRecord.a8")}}</a-button>
          </a-col>
        </a-row>
      </div>
    <!-- 表格区域 -->
    </div>
    <div class="main" ref="main">
      <my-tabletitle>
        {{ $t("visitorBookingRecord.a9") }}
        <template v-slot:name>
          <a-button style="width:100%;" type="primary" icon="file-text" size="small" ghost :disabled="list.length == 0" @click="csvExport">
            {{ $t("alarm.a51") }}
          </a-button>
        </template>
      </my-tabletitle>
      <a-table :columns="columns" :dataSource="list" :pagination="pagination" :scroll="size" :rowKey="(record) => record.key">
        <template slot="operate" slot-scope="text, record">          
          <div>
            <a-popover placement="left">
              <template slot="content">
                <a-button type="primary" style="margin-right: 15px;" @click="() => onCheckDetails(record)">{{ $t("visitorBookingRecord.a29") }}</a-button>
                <a-button v-if="record.orderStatus == 0" type="primary" @click="() => onCancelVisitorOrders(record)">{{ $t("visitorBookingRecord.a49") }}</a-button>
              </template>
              <a>{{ $t("visitorBookingRecord.a50") }}</a>
            </a-popover>
          </div>
        </template>        
      </a-table>
      <a-modal v-model="visible" :title="modalName" width=1200px :body-style="bodystyle" :footer="null" @ok="handleOk" @cancel="handleCancel">
        <my-visitorDetailsModel :visitorDetails="visitorDetails"></my-visitorDetailsModel>
      </a-modal>
    </div>
  </div>
</template>

<script>
import tabletitle from "../../components/Title/tabletitle"
import headerTitle from "../../components/Title/headerTitle"
import visitorDetailsModel from "./visitorDetailsModel"
import { getVisitorOrderList,getVisitorOrderInfo,getVisitorDictionary,cancelVisitorOrders } from "../../api/visitor"
import { tableSort, csvDownload } from "../../utils/utils"
import moment from "moment"


export default {
  name: "visitorBookingRecord",
  data() {
    return {   
      bodystyle:{
        height: '720px',
        // overflow: 'hidden',
        // overflowY: 'scroll',
      },
      modalName:this.$t("visitorBookingRecord.a29"),
      visible:false,
      visitorDetails:{},
      form:{
            date1: moment().startOf("day"),
            date2: moment().endOf("day"),
            value1: undefined,
            value2: undefined,
            condition1: "personPhone",
            condition2: "visitorPhone",
            condition3: 0,   
            condition4: '',
            condition5: '',
            condition6: '',
            memo:'' ,
          },
      conditions1:[
                  {key:"managerCode",title:this.$t("visitorBookingRecord.a22")},
                  {key:"personName",title:this.$t("visitorBookingRecord.a23")},
                  {key:"personPhone",title:this.$t("visitorBookingRecord.a28")}],
      conditions2:[
                  {key:"visitorName",title:this.$t("visitorBookingRecord.a12")},
                  {key:"visitorPhone",title:this.$t("visitorBookingRecord.a13")},
                  {key:"visitorCompany",title:this.$t("visitorBookingRecord.a14")},
                  {key:"visitorCarNo",title:this.$t("visitorBookingRecord.a21")},],
      conditions3:[
                  {key:0,title:this.$t("visitorBookingRecord.a17")},
                  {key:1,title:this.$t("visitorBookingRecord.a18")},
                  {key:2,title:this.$t("visitorBookingRecord.a26")}],
      conditions4:[],
      conditions5:[],
      conditions6:[
                  {key:"",title:this.$t("visitorBookingRecord.a53")},
                  {key:0,title:this.$t("visitorBookingRecord.a39")},
                  {key:1,title:this.$t("visitorBookingRecord.a40")},
                  {key:2,title:this.$t("visitorBookingRecord.a41")},
                  {key:3,title:this.$t("visitorBookingRecord.a42")},
                  {key:4,title:this.$t("visitorBookingRecord.a43")},
                  {key:5,title:this.$t("visitorBookingRecord.a44")},
                  {key:6,title:this.$t("visitorBookingRecord.a45")},
                  {key:7,title:this.$t("visitorBookingRecord.a46")},
                  {key:8,title:this.$t("visitorBookingRecord.a47")}
                  ],
      loading: false,
      startDate: undefined,
      size: { x: 1200,y: 240 },
      list: [],
      pagination: {
        total: 0, //数据总数
        pageSize: 100, //每页中显示10条数据
        showTotal: (total) => `${total} ${this.$t("visitorBookingRecord.a11")}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
        onShowSizeChange: (current, pageSize) => {
          this.pagination.defaultCurrent = 1;
          this.pagination.pageSize = pageSize;
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.pagination.defaultCurrent = current;
          this.pagination.pageSize = size;
        },
      },
      columns: [
        {
          title: "NO",
          customRender: (text, record, index) =>
            `${
              (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
              index +
              1
            }`,
          width: 60,
        },
        {
          title: this.$t("visitorBookingRecord.a12"),
          dataIndex: "visitorName",
          sorter: (a, b) => {
            return tableSort(a, b, "visitorName");
          },
          
          width: 200,
          // ellipsis: true,
        },
        {
          title: this.$t("visitorBookingRecord.a13"),
          dataIndex: "visitorPhone",
          sorter: (a, b) => {
            return tableSort(a, b, "visitorPhone");
          },
          
          width: 150,
          // ellipsis: true,
        },
        {
          title: this.$t("visitorBookingRecord.a14"),
          dataIndex: "visitorCompany",
          sorter: (a, b) => {
            return tableSort(a, b, "visitorCompany");
          },
          
          width: 200,
          // ellipsis: true,
        },
        {
          title: this.$t("visitorBookingRecord.a21"),
          dataIndex: "visitorCarNo",
          sorter: (a, b) => {
            return tableSort(a, b, "visitorCarNo");
          },
          width: 200,
          // ellipsis: true,
        },
        {
          title: this.$t('visitorRecord.a24'),//'备注',
          dataIndex: "memo",
          sorter: (a, b) => {
            return tableSort(a, b, "memo");
          },
          width: 200,
          // ellipsis: true,
        },

        {
          title: this.$t("visitorBookingRecord.a15"),
          dataIndex: "orderStatus",
          customRender: (text, record, index) =>{
            return this.getOrderStatusName(record,text,index);
          },
          sorter: (a, b) => {
            return tableSort(a, b, "orderStatus");
          },
          width: 150,
          // ellipsis: true,
        },
        {
          title: this.$t("visitorBookingRecord.a16"),
          dataIndex: "visitorStatus",
          customRender: (text, record, index) =>{
            return this.getVisitStatusName(record,text,index);
          },
          sorter: (a, b) => {
            return tableSort(a, b, "visitorStatus");
          },
          width: 150,
          // ellipsis: true,
        },
        {
          title: this.$t("visitorBookingRecord.a17"),
          dataIndex: "bookStartTime",
          sorter: (a, b) => {
            return tableSort(a, b, "bookStartTime");
          },
          width: 180,
          // ellipsis: true,
        },
        {
          title: this.$t("visitorBookingRecord.a18"),
          dataIndex: "bookEndTime",
          sorter: (a, b) => {
            return tableSort(a, b, "bookEndTime");
          },
          width: 180,
          // ellipsis: true,
        },
        {
          title: this.$t("visitorBookingRecord.a19"),
          dataIndex: "visitorReason",
          customRender: (text, record, index) =>{
            return this.getVisitReasonName(record,text,index);
          },
          sorter: (a, b) => {
            return tableSort(a, b, "visitorReason");
          },
          width: 200,
          // ellipsis: true,
        },
        {
          title: this.$t("visitorBookingRecord.a20"),
          dataIndex: "visitorRemark",
          sorter: (a, b) => {
            return tableSort(a, b, "visitorRemark");
          },
          width: 200,
          // ellipsis: true,
        },
        {
          title:  this.$t("visitorBookingRecord.a22"),
          dataIndex: "managerCode",
          sorter: (a, b) => {
            return tableSort(a, b, "managerCode");
          },
          width: 200,
          // ellipsis: true,
        },
        {
          title: this.$t("visitorBookingRecord.a28"),
          dataIndex: "personPhone",
          sorter: (a, b) => {
            return tableSort(a, b, "personPhone");
          },
          width: 200,
          // ellipsis: true,
        },
        {
          title: this.$t("visitorBookingRecord.a23"),
          dataIndex: "personName",
          sorter: (a, b) => {
            return tableSort(a, b, "personName");
          },
          width: 200,
          // ellipsis: true,
        },
        {
          title: this.$t("visitorBookingRecord.a24"),
          dataIndex: "dept",
          sorter: (a, b) => {
            return tableSort(a, b, "dept");
          },
          width: 300,
          // ellipsis: true,
        },
        {
          title: this.$t("visitorBookingRecord.a25"),
          dataIndex: "visitSum",
          sorter: (a, b) => {
            return tableSort(a, b, "visitSum");
          },
          width: 200,
          // ellipsis: true,
        },
        {
          title: this.$t("visitorBookingRecord.a26"),
          dataIndex: "createDate",
          sorter: (a, b) => {
            return tableSort(a, b, "createDate");
          },
          width: 150,
           // ellipsis: true,
        },
        {
          title: this.$t("throughrecords.a27"),
          dataIndex: "operate",
          scopedSlots: { customRender: "operate" },
          width: 150,
          fixed: "right",
        },
      ],
    }
  },
  computed:{
    // 受访人信息输入框占位符显示内容
    Interviewee: function (){
      let conditions1 = this.form.condition1
      if(conditions1 === 'managerCode'){
        return this.$t("visitorBookingRecord.a22")
      }else if(conditions1 === 'personName'){
        return this.$t("visitorBookingRecord.a23")
      }else if(conditions1 === 'personPhone'){
        return this.$t("visitorBookingRecord.a28")
      }else{
        return ''
      }
    },
    // 访客信息输入框占位符显示内容
    visitor: function (){
      let conditions2 = this.form.condition2
      if(conditions2 === 'visitorName'){
        return this.$t("visitorBookingRecord.a12")
      }else if(conditions2 === 'visitorPhone'){
        return this.$t("visitorBookingRecord.a13")
      }else if(conditions2 === 'visitorCompany'){
        return this.$t("visitorBookingRecord.a14")
      }else if(conditions2 === 'visitorCarNo'){
        return this.$t("visitorBookingRecord.a21")
      }else{
        return ''
      }
    }
  },
  activated(){
    this.list = [];
    this.form={
            date1: moment().startOf("day"),
            date2: moment().endOf("day"),
            value1: undefined,
            value2: undefined,
            condition1: "personPhone",
            condition2: "visitorPhone",
            condition3: 0,   
            condition4: '',
            condition5: '',
            condition6: '',
            memo:'' ,
        };
  },
  mounted() {
    // 工单状态列表和到访状态列表获取
    this.getVisitorDictionary()
    this.tableSize()
    window.addEventListener("resize", this.tableSize)
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.tableSize)
  },
  methods: {
    getOrderStatusName(record,text,index){
      let item = undefined;
      this.conditions4.forEach(element => {
          if(element.value*1===record.orderStatus*1){
            item = element;
          } 
      });
      if(item){
          return item.name;
      }
      return "-";
    },
    getVisitStatusName(record,text,index){
      let item = undefined;
      this.conditions5.forEach(element => {
          if(element.value*1===record.visitorStatus*1){
            item = element;
          } 
      });
      if(item){
          return item.name;
      }
      return "-";
    },
    getVisitReasonName(record,text,index){
      let item = undefined;
      this.conditions6.forEach(element => {
          if(element.key+""===record.visitorReason){
            item = element;
          } 
      });
      if(item){
          return item.title;
      }
      return (record.visitorReason?record.visitorReason:"-");
    },
    moment,
    handleOk(e) {
      console.log("handleOk e:",e)
      this.visible = false
    },
    handleCancel(e){
      console.log("handleCancel e:",e);
      this.visible = false
      this.flush()
    },
    flush(){
      this.list.forEach(order => {
          if(order.orderId == this.visitorDetails.orderId){
              order.visitorStatus	     = this.visitorDetails.visitorStatus		  ;
              order.visitorStatusName  = this.visitorDetails.visitorStatusName  ;
              order.orderStatus        = this.visitorDetails.orderStatus        ;
              order.orderStatusName    = this.visitorDetails.orderStatusName    ;
              order.memo               = this.visitorDetails.memo               ;
          }
      });
    },
    onCancelVisitorOrders(record){
      console.log("onCancelVisitorOrders record:",record);
      this.cancelVisitorOrders(record.orderId+"");
    },
    //查看记录详情
    onCheckDetails(record){
      console.log("onCheckDetails record:",record);
      this.visible = true;
      this.visitorDetails = {};
      this.getVisitorOrderDetails(record.siteId,record.orderId);
    },
    cancelVisitorOrders(orderId){
      let par={orderIds:orderId};
      console.log("cancelVisitorOrders par:",par);
      cancelVisitorOrders(par)
        .then((res) => {              
          this.loading = false;
          let { data } = res;  
          console.log("cancelVisitorOrders res:",data);
          if(data){
            this.$message.info(this.$t('general.a6')); 
            this.inquire();
          } else{
            this.$message.error(this.$t('general.a7')); 
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    getVisitorOrderDetails(siteId,orderId){
      getVisitorOrderInfo(siteId,orderId)
        .then((res) => {              
          this.loading = false;
          if(res.errorCode=='00' || res.errorCode=='03'){
              let { data } = res;  
              let idx =1;
              data.visitors.forEach(element => {
                element.no = idx;
                idx++;
              });
              idx =1;
              data.events.forEach(element => {
                element.no = idx;
                idx++;
              });
              this.visitorDetails=data;
              
              console.log("getVisitorOrderDetails res:",data);
          }else{
              this.$message.error(res.msg); 
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    // 表格大小调节
    tableSize() {
      let height = this.$refs.main.clientHeight
      let tableHeight = height - 160
      this.size.y = tableHeight
    },
    //获取访客参数字典信息列表
    getVisitorDictionary(){
      this.loading = true
      getVisitorDictionary(this.$route.query.id)
            .then((res) => {              
              this.loading = false;
              let { data } = res;           
              console.log("getVisitorDictionary data:",data);
              if(data!=null&&data.length>0){
                for(let i=0;i<data.length;i++){
                  switch(data[i].kind){
                    case "VISITOR_PASS_TYPE": 
                      break;
                    case "VISITOR_ACCESS_STATUS":
                      let blankAccessStatus={kind: "VISITOR_ACCESS_STATUS", name: this.$t("visitorBookingRecord.a54"), value: ""};
                      this.conditions5.push(blankAccessStatus);
                      this.conditions5=this.conditions5.concat(data[i].data);
                      break;
                    case "VISITOR_ORDER_STATUS":
                      let blankOrderStatus={kind: "VISITOR_ORDER_STATUS", name: this.$t("visitorBookingRecord.a55"), value: ""};
                      this.conditions4.push(blankOrderStatus);
                      this.conditions4=this.conditions4.concat(data[i].data);
                      break;
                  }
                }
              }
              console.log("getVisitorDictionary conditions6:",this.conditions6);
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
            });
    },
    // 条件查询方法
    inquire() {
      this.loading = true
      const date1 = this.form.date1
      const date2 = this.form.date2
      if(!date1 || !date2){
        this.form.date1 = moment().startOf("day")
        this.form.date2 = moment().endOf("day")
      }
      let par = {
        dateSelected:this.form.condition3,
        strBookStartTimeStar: this.form.condition3==0?this.form.date1.format("YYYY/MM/DD HH:mm:ss"):"",
        strBookStartTimeEnd: this.form.condition3==0?this.form.date2.format("YYYY/MM/DD HH:mm:ss"):"",  
        strBookEndTimeStar:this.form.condition3==1?this.form.date1.format("YYYY/MM/DD HH:mm:ss"):"",
        strBookEndTimeEnd:this.form.condition3==1?this.form.date2.format("YYYY/MM/DD HH:mm:ss"):"", 
        strDateTimeStar:this.form.condition3==2?this.form.date1.format("YYYY/MM/DD HH:mm:ss"):"",
        strDateTimeEnd:this.form.condition3==2?this.form.date2.format("YYYY/MM/DD HH:mm:ss"):"", 
        orderNo:this.form.condition1=="orderNo"?this.form.value1:"",
        personId:this.form.condition1=="personId"?this.form.value1:"",
        personName:this.form.condition1=="personName"?this.form.value1:"",
        personPhone:this.form.condition1=="personPhone"?this.form.value1:"",
        managerCode:this.form.condition2=="managerCode"?this.form.value1:"",
        visitorName:this.form.condition2=="visitorName"?this.form.value2:"",
        visitorPhone:this.form.condition2=="visitorPhone"?this.form.value2:"",
        visitorCompany:this.form.condition2=="visitorCompany"?this.form.value2:"",
        visitorCarNo:this.form.condition2=="visitorCarNo"?this.form.value2:"",
        orderStatus:this.form.condition4,
        visitorStatus:this.form.condition5,
        visitorReason:this.form.condition6,
        memo:this.form.memo,
      }
      console.log("getVisitorOrderList par:",par);
      getVisitorOrderList(this.$route.query.id,par)
        .then((res) => {              
          this.loading = false;
          if(res.errorCode=='00' || res.errorCode=='03'){
              let { data } = res;
              if(data!=null){
                this.list = data;  
                for (let i = 0; i < this.list.length; i++) {
                  this.list[i].key = i+1;
                }              
              }  
              else
                this.list.length=0;            
              console.log("getVisitorOrderList res:",this.list);
          }else{
              this.$message.error(res.msg); 
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    // 初始日期/时间选择变化回调
    handleCalendarChange(value) {
      const type = this.form.condition3
      const currentDateValue = moment()
      const startDateValue = moment(value._d)
      const endDateValue = this.form.date2
      const maxDateValue = moment(value._d).add(1, 'months')
      if(value){
        if(type === 0){
          if(endDateValue > maxDateValue){
            this.form.date2 = moment(value._d).add(1, 'months').subtract(1, 'd').endOf("day")
          }else if(startDateValue > endDateValue){
            this.form.date2 = moment(value._d).add(1, 'months').subtract(1, 'd').endOf("day")
          }
        }else{
          if(endDateValue > maxDateValue){
            this.form.date2 = moment(value._d).add(1, 'months').subtract(1, 'd').endOf("day")
          }else if(startDateValue > endDateValue){
            if(currentDateValue > maxDateValue){
              this.form.date2 = moment(value._d).add(1, 'months').subtract(1, 'd').endOf("day")
            }else{
              this.form.date2 = moment().endOf('day')
            }
          }
        }
      }
    },
    // 时间类型选择回调，根据不同的类型调整初始/终止日期时间的值
    dateTypeSelect(value){
      const currentDateValue = moment()
      const startDateValue = moment(this.form.date1._d)
      const endDateValue = moment(this.form.date2._d)
      if(value !== 0){
        if(startDateValue > currentDateValue){
          this.form.date1 = moment().startOf('day')
          this.form.date2 = moment().endOf('day')
        }else if(endDateValue > currentDateValue){
          this.form.date2 = moment().endOf('day')
        }
      }
    },
    // 初始日期/时间的不可选范围
    disabledStartDate(startValue) {
      const type = this.form.condition3
      const currentDateValue = moment().endOf('day')
      if(type === 0){
        return false
      }else {
        return startValue > currentDateValue
      }
    },
    // 终止日期/时间的日期不可选范围
    disabledEndDate(endValue) {
      const type = this.form.condition3
      const starDatetValue = this.form.date1
      const currentDateValue = moment().endOf('day')
      if(type === 0){
        return starDatetValue > endValue || endValue > moment(starDatetValue._d).add(1,'months')
      }else {
        return endValue > currentDateValue || endValue > moment(starDatetValue._d).add(1,'months') || starDatetValue > endValue
      }
    },
    // 表格CSV导出
    csvExport() {
      const date1 = this.form.date1.format("YYYYMMDD")
      const date2 = this.form.date2.format("YYYYMMDD")
      const name =this.$t("visitorBookingRecord.a2")
      const fileName = `${date1}~${date2}${name}`
      //转换列表数据对象   
      csvDownload(this.list, this.columns, fileName)
    }
  },
  components: {
    "my-tabletitle": tabletitle,
    "my-headertitle": headerTitle,
    "my-visitorDetailsModel":visitorDetailsModel
  }
}
</script>

<style scoped>
#visitorBookingRecord {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.header {
  min-width: 850px;
  height: 161px;
  padding: 0 20px;
  overflow: hidden;
}
.query{
  margin: 15px 0px;
}
.main {
  height: calc(100% - 161px);
  width: 100%;
  min-width: 670px;
  padding: 0px 20px;
  overflow: hidden;
}
div /deep/.ant-modal-body{padding: 10px 10px 10px 20px; }
div /deep/.ant-table-tbody > tr > td{
  padding: 5px 10px;
  overflow-wrap:normal;
}
div /deep/.ant-table-thead > tr{
  height: auto;
}
div /deep/.ant-table-thead > tr > th{
  overflow-wrap:normal;
}
</style>